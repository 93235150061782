
import Vue from 'vue';
import eventBus from '@/application/eventBus.ts';
import KTitle from '@/components/layout/KTitle.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { landingPageMode } from '@/application/enums/landingPageMode';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import { mapGetters } from 'vuex';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import PlaceholderWidget from '@/modules/dashboard/widgets/PlaceholderWidget.vue';
import InvoiceWidget from '@/modules/dashboard/widgets/InvoiceWidget.vue';
import EmployerWidget from '@/modules/dashboard/widgets/EmployerWidget.vue';
import TimeRegistrationWidget from '@/modules/dashboard/widgets/TimeRegistrationWidget.vue';
import CandidateWidget from '@/modules/dashboard/widgets/CandidateWidget.vue';
import PlacementWidget from '@/modules/dashboard/widgets/PlacementWidget.vue';
import TaskWidget from '@/modules/dashboard/widgets/TaskWidget.vue';

interface ComponentData {
  itemsPerPage: number
  landingPageMode: number
}

export default Vue.extend({
  name: 'Dashboard',
  components: {
    KTitle,
    PageLayout,
    KRadioGroup,
    PlaceholderWidget,
    InvoiceWidget,
    EmployerWidget,
    TimeRegistrationWidget,
    CandidateWidget,
    PlacementWidget,
    TaskWidget,
  },
  data: (): ComponentData => ({
    itemsPerPage: 5,
    landingPageMode: landingPageMode.ALL_ORGANISATIONS,
  }),
  computed: {
    ...mapGetters({
      profile: 'authorisation/profile',
    }),
    landingPageModes() {
      return getTranslatedEnumOptions(landingPageMode, 'landingPage.modes').filter((mode) => [landingPageMode.ALL_ORGANISATIONS, landingPageMode.MY_ORGANISATIONS].includes(mode.value));
    },
    assignedToUser() {
      return this.landingPageMode === landingPageMode.MY_ORGANISATIONS ? [this.profile.id] : [];
    },
  },
  mounted() {
    eventBus.$emit('setBreadcrumbs', []);
  },
});
