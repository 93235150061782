<template>
  <v-col
    cols="12"
    :lg="cols"
  >
    <v-sheet class="k-transparent pt-2 px-4 pb-4 fill-height">
      <div class="d-flex justify-space-between mb-2">
        <span class="text-h3 primary--text">{{ title }}</span>
      </div>
    </v-sheet>
  </v-col>
</template>

<script>
export default {
  name: 'PlaceholderWidget',
  props: {
    cols: {
      type: Number,
      default: 6,
    },
    title: {
      type: String,
      default: 'Placeholder',
    },
  },
};
</script>
