<template>
  <v-col
    cols="12"
    :lg="cols"
  >
    <v-sheet class="k-transparent pt-2 px-4 pb-4 fill-height">
      <div class="d-flex justify-space-between mb-2">
        <component
          :is="linkTo ? 'router-link' : 'span'"
          class="text-h3 primary--text"
          :to="linkTo"
        >
          {{ $t(`landingPage.${resource}.title`) }}
        </component>
        <ul class="widget-filters d-flex align-center font-weight-bold primary--text text-button">
          <li
            v-for="option in [...filters.options, { text: 'total' }]"
            :key="option.text"
            class="d-block px-2"
            :class="{ active: activeFilter.text === option.text }"
            @click="setActiveFilter(option)"
          >
            {{ $t(`landingPage.${resource}.filters.${option.text}`) }}
          </li>
        </ul>
      </div>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :language-prefix="`landingPage.${resource}.columns`"
        hide-default-footer
        disable-sort
        no-actions
        @click:row="goToTask($event)"
      >
        <template #item.priorityId="{ item }">
          <TaskPriorityIcon
            v-if="item.priorityId"
            :priority="item.priorityId"
          />
        </template>
      </k-crud-table>
    </v-sheet>
  </v-col>
</template>

<script>
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import { index } from '@/modules/dashboard/api';
import TaskPriorityIcon from '@/modules/task/components/TaskPriorityIcon.vue';
import taskToRoute from '@/modules/task/util/taskToRoute.js';

export default {
  name: 'TaskWidget',
  components: {
    KCrudTable,
    TaskPriorityIcon,
  },
  props: {
    resource: {
      type: String,
      require: true,
    },
    assignedToUser: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: 6,
    },
    visibleColumns: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    linkTo: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      filters: {
        field: 'refersTo',
        options: [
          {
            text: 'candidate',
            value: ['candidate'],
          },
          {
            text: 'shift',
            value: ['shift'],
          },
          {
            text: 'timeRegistration',
            value: ['timeRegistration'],
          },
          {
            text: 'claim',
            value: ['claim'],
          },
        ],
      },
      activeFilter: { text: 'total' },
    };
  },
  computed: {
    crudHeaders() {
      return this.visibleColumns.map(column => typeof column === 'string' ? { value: column } : column );
    },
  },
  watch: {
    activeFilter() {
      this.$refs.table.reload();
    },
    assignedToUser() {
      this.$refs.table.reload();
    },
  },
  methods: {
    indexRequest(...[page, perPage, search, sortBy, descending, params]) {

      perPage = this.itemsPerPage;
      params = {
        assignedUserIds: this.assignedToUser,
        [this.filters.field]: this.activeFilter.value,
      };

      return index(this.resource, ...[page, perPage, search, sortBy, descending, params]);
    },
    setActiveFilter(filter) {
      this.activeFilter = filter;
    },
    goToTask(task) {
      const route = taskToRoute(task.refersTo, task.entities);
      if (!route) return;
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
ul.widget-filters {
  list-style: none;
  li {
    font-size: 11px;
    opacity: 0.35;
    transition: opacity 120ms ease;
    cursor: pointer;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
}
</style>
